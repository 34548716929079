import IconColorfulDotGrid from '@/assets/icons/plain/colorful-dot-grid.svg'
import IconFooterSquares from '@/assets/icons/plain/footer-squares.svg'
import IconFooterSquaresBlack from '@/assets/icons/plain/footer-squares-black.svg'
import IconGoogle from '@/assets/icons/plain/google.svg'
import IconBlik from '@/assets/icons/plain/icon-blik.svg'
import IconCard from '@/assets/icons/plain/icon-card.svg'
import IconMasterpass from '@/assets/icons/plain/icon-masterpass.svg'
import IconPayU from '@/assets/icons/plain/icon-payu.svg'
import IconTransfer from '@/assets/icons/plain/icon-transfer.svg'
import IconInstagramOriginal from '@/assets/icons/plain/instagram-original.svg'
import IconTiktokOriginal from '@/assets/icons/plain/tiktok-original.svg'
import IconYoutubeOriginal from '@/assets/icons/plain/youtube-original.svg'
import IconAccessories from '@/assets/icons/purged/accessories.svg'
import IconApple from '@/assets/icons/purged/apple.svg'
import IconArrow from '@/assets/icons/purged/arrow.svg'
import IconArrowWide from '@/assets/icons/purged/arrow-wide.svg'
import IconAudiobook from '@/assets/icons/purged/audiobook.svg'
import IconBell from '@/assets/icons/purged/bell.svg'
import IconBilling from '@/assets/icons/purged/billing.svg'
import IconBooks from '@/assets/icons/purged/books.svg'
import IconBoxes from '@/assets/icons/purged/boxes.svg'
import IconCalendar from '@/assets/icons/purged/calendar.svg'
import IconCheckMark from '@/assets/icons/purged/check-mark.svg'
import IconChevron from '@/assets/icons/purged/chevron.svg'
import IconClothes from '@/assets/icons/purged/clothes.svg'
import IconClothesHanger from '@/assets/icons/purged/clothes-hanger.svg'
import IconCopy from '@/assets/icons/purged/copy.svg'
import IconCosmetics from '@/assets/icons/purged/cosmetics.svg'
import IconCross from '@/assets/icons/purged/cross.svg'
import IconDelivery from '@/assets/icons/purged/delivery.svg'
import IconDeliveryTruck from '@/assets/icons/purged/delivery-truck.svg'
import IconDevices from '@/assets/icons/purged/devices.svg'
import IconDigitalProduct from '@/assets/icons/purged/digital-product.svg'
import IconDownload from '@/assets/icons/purged/download.svg'
import IconEbook from '@/assets/icons/purged/ebook.svg'
import IconEdit from '@/assets/icons/purged/edit.svg'
import IconEducation from '@/assets/icons/purged/education.svg'
import IconEllipsis from '@/assets/icons/purged/ellipsis.svg'
import IconFacebookCircular from '@/assets/icons/purged/facebook-circular.svg'
import IconFacebookOutline from '@/assets/icons/purged/facebook-outline.svg'
import FastDelivery from '@/assets/icons/purged/fast-delivery.svg'
import IconFile from '@/assets/icons/purged/file.svg'
import FreeDelivery from '@/assets/icons/purged/free-delivery.svg'
import IconGuitar from '@/assets/icons/purged/guitar.svg'
import IconHeart from '@/assets/icons/purged/heart.svg'
import IconHeartFull from '@/assets/icons/purged/heart-full.svg'
import IconHelp from '@/assets/icons/purged/help.svg'
import IconHome from '@/assets/icons/purged/home.svg'
import IconHomeAccessories from '@/assets/icons/purged/home-accessories.svg'
import IconInfo from '@/assets/icons/purged/info.svg'
import IconInstagram from '@/assets/icons/purged/instagram.svg'
import IconJewellery from '@/assets/icons/purged/jewellery.svg'
import IconLightbulb from '@/assets/icons/purged/lightbulb.svg'
import IconLike from '@/assets/icons/purged/like.svg'
import IconLocation from '@/assets/icons/purged/location.svg'
import IconLogo from '@/assets/icons/purged/logo.svg'
import IconMarker from '@/assets/icons/purged/marker.svg'
import IconOther from '@/assets/icons/purged/other.svg'
import IconOtherDigital from '@/assets/icons/purged/other-digital.svg'
import IconPayment from '@/assets/icons/purged/payment.svg'
import IconPlainDotGrid from '@/assets/icons/purged/plain-dot-grid.svg'
import IconPlaners from '@/assets/icons/purged/planers.svg'
import IconPlus from '@/assets/icons/purged/plus.svg'
import IconPlusCircle from '@/assets/icons/purged/plus-circle.svg'
import IconPoster from '@/assets/icons/purged/poster.svg'
import IconPresets from '@/assets/icons/purged/presets.svg'
import IconQuestions from '@/assets/icons/purged/questions.svg'
import Return from '@/assets/icons/purged/return.svg'
import IconRocket from '@/assets/icons/purged/rocket.svg'
import IconSchoolAccessories from '@/assets/icons/purged/school-accessories.svg'
import IconSearch from '@/assets/icons/purged/search.svg'
import IconSecurity from '@/assets/icons/purged/security.svg'
import IconSelect from '@/assets/icons/purged/select.svg'
import IconSend from '@/assets/icons/purged/send.svg'
import IconShoppingBag from '@/assets/icons/purged/shopping-bag.svg'
import IconShoppingCart from '@/assets/icons/purged/shopping-cart.svg'
import IconSignOut from '@/assets/icons/purged/sign-out.svg'
import IconSofa from '@/assets/icons/purged/sofa.svg'
import IconStar from '@/assets/icons/purged/star.svg'
import IconSurprise from '@/assets/icons/purged/surprise.svg'
import IconTikTok from '@/assets/icons/purged/tiktok.svg'
import IconTrash from '@/assets/icons/purged/trash.svg'
import IconUser from '@/assets/icons/purged/user.svg'
import IconVideoBrands from '@/assets/icons/purged/video-brands.svg'
import IconVideoFamily from '@/assets/icons/purged/video-family.svg'
import IconVideoFiles from '@/assets/icons/purged/video-files.svg'
import IconVideoForYou from '@/assets/icons/purged/video-for-you.svg'
import IconWarning from '@/assets/icons/purged/warning.svg'
import IconYoutube from '@/assets/icons/purged/youtube.svg'

const ICONS = {
  accessories: <IconAccessories />,
  apple: <IconApple />,
  arrow: <IconArrow />,
  arrow_wide: <IconArrowWide />,
  audiobook: <IconAudiobook />,
  bell: <IconBell />,
  billing: <IconBilling />,
  blik: <IconBlik />,
  books: <IconBooks />,
  boxes: <IconBoxes />,
  calendar: <IconCalendar />,
  card: <IconCard />,
  check_mark: <IconCheckMark />,
  chevron: <IconChevron />,
  clothes: <IconClothes />,
  clothes_hanger: <IconClothesHanger />,
  colorful_dot_grid: <IconColorfulDotGrid />,
  copy: <IconCopy />,
  cross: <IconCross />,
  cosmetics: <IconCosmetics />,
  delivery: <IconDelivery />,
  deliveryTruck: <IconDeliveryTruck />,
  devices: <IconDevices />,
  digital_product: <IconDigitalProduct />,
  download: <IconDownload />,
  ebook: <IconEbook />,
  edit: <IconEdit />,
  education: <IconEducation />,
  ellipsis: <IconEllipsis />,
  facebook_circular: <IconFacebookCircular />,
  facebook_outline: <IconFacebookOutline />,
  fast_delivery: <FastDelivery />,
  file: <IconFile />,
  footer_square: <IconFooterSquares />,
  footer_square_black: <IconFooterSquaresBlack />,
  free_delivery: <FreeDelivery />,
  guitar: <IconGuitar />,
  google: <IconGoogle />,
  heart: <IconHeart />,
  heart_full: <IconHeartFull />,
  help: <IconHelp />,
  home: <IconHome />,
  homeAccessories: <IconHomeAccessories />,
  info: <IconInfo />,
  instagram: <IconInstagram />,
  instagram_original: <IconInstagramOriginal />,
  jewellery: <IconJewellery />,
  lightbulb: <IconLightbulb />,
  like: <IconLike />,
  location: <IconLocation />,
  logo: <IconLogo />,
  marker: <IconMarker />,
  masterpass: <IconMasterpass />,
  other: <IconOther />,
  otherDigital: <IconOtherDigital />,
  payment: <IconPayment />,
  payu: <IconPayU />,
  plain_dot_grid: <IconPlainDotGrid />,
  planers: <IconPlaners />,
  plus: <IconPlus />,
  plus_circle: <IconPlusCircle />,
  poster: <IconPoster />,
  presets: <IconPresets />,
  return: <Return />,
  rocket: <IconRocket />,
  search: <IconSearch />,
  security: <IconSecurity />,
  select: <IconSelect />,
  send: <IconSend />,
  schoolAccessories: <IconSchoolAccessories />,
  shopping_bag: <IconShoppingBag />,
  shopping_cart: <IconShoppingCart />,
  sign_out: <IconSignOut />,
  sofa: <IconSofa />,
  star: <IconStar />,
  surprise: <IconSurprise />,
  tiktok: <IconTikTok />,
  tiktok_original: <IconTiktokOriginal />,
  transfer: <IconTransfer />,
  trash: <IconTrash />,
  questions: <IconQuestions />,
  user: <IconUser />,
  videoBrands: <IconVideoBrands />,
  videoFamily: <IconVideoFamily />,
  videoForYou: <IconVideoForYou />,
  warning: <IconWarning />,
  video_files: <IconVideoFiles />,
  youtube_original: <IconYoutubeOriginal />,
  youtube: <IconYoutube />,
}

export default ICONS
