import PRODUCT_AS_FANSI_KIND from '@/constants/productAsFansiKind'
import Product from '@/ts/Product'

function applyProductLayer(product: Product) {
  const { id, creator, entries, price, type } = product

  const layer = {
    event: 'content',
    page_type: 'product',
    product_ids: id,
    creator: creator.nickname,
    category: PRODUCT_AS_FANSI_KIND[type],
    total_value:
      type === 'Product::FansiExperience'
        ? entries[0]?.price?.value
        : price?.value,
  }

  return layer
}

export default applyProductLayer
