import { CartItem } from '@/ts/Cart'

import updateCollection from './update/updateCollection'

function updateProduct(cartItems: CartItem[], product: CartItem) {
  if (product.type === 'Product::FansiCollection') {
    return updateCollection(cartItems, product)
  }

  return cartItems
}

export default updateProduct
