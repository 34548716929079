import signOut from '@/api/requests/auth/signOut'
import { showModal } from '@/store/slices/modal'

async function handleAuthParam() {
  await signOut('user', false)

  showModal('auth', 'providers')
}

export default handleAuthParam
