import { showModal } from '@/store/slices/modal'
import { CartItem } from '@/ts/Cart'

function addExperience(cart: CartItem[], product: CartItem) {
  const existingExperience = cart.find((item) => item.id === product.id)

  if (existingExperience) {
    if (existingExperience?.contest_entry_id !== product.contest_entry_id) {
      Object.assign(existingExperience, product)
      showModal('cart', 'added_to_cart')
    } else {
      showModal('cart', 'already_in_cart')
    }

    return cart
  }

  showModal('cart', 'added_to_cart')
  return [...cart, product]
}

export default addExperience
