import Facebook from '@/services/Facebook'
import { CartItem } from '@/ts/Cart'

function updateCollection(cart: CartItem[], product: CartItem) {
  const { price, type, size_id } = product

  const currentCollection = cart.find((item) => {
    if (item.type === type) {
      return item.size_id === size_id
    }
  })

  const quantityDiff = product.quantity - (currentCollection?.quantity || 0)

  if (quantityDiff > 0) {
    Facebook.track('AddToCart', {
      currency: price?.currency,
      value: (quantityDiff * price?.value) / 100,
      content_ids: [product?.id],
    })
  }

  return cart.map((item) => {
    if (item.type === type) {
      if (item.size_id === size_id) {
        return product
      }
    }
    return item
  })
}

export default updateCollection
