import { serialize } from 'object-to-formdata'

import excludeBlankKeys from '@/helpers/excludeBlankKeys'
import { toast } from '@/molecules/Toast'
import { hideModals } from '@/store/slices/modal'

import { ApiMethod, RequestOptions } from '..'

async function handleRequest<TS, TE>(
  props: Request
): Promise<Success<TS> | Error<TE> | Http500> {
  const { endpoint, method, options } = props

  const config: RequestInit = {
    headers: excludeBlankKeys({
      ...(options?.body && { 'Content-Type': 'application/json' }),
      Cookie: options?.cookies || '',
    }),
    credentials: 'include',
    method,
    body: options?.formData
      ? serialize(options.formData)
      : JSON.stringify(options?.body),
  }

  try {
    const response = await fetch(endpoint, config)
    const { ok, status: code } = response

    const content = response.status === 204 ? null : await response.json()
    const status: Status = ok ? 'success' : 'error'

    const data = { status, code, content }

    return data
  } catch (error) {
    hideModals()
    toast.error('server', { id: '500' })

    return { status: 500, code: 500, content: error }
  }
}

interface Request {
  endpoint: string
  method: ApiMethod
  options?: RequestOptions
}

interface ApiResponse<TContent> {
  code: number
  content: TContent
}
interface Success<TS> extends ApiResponse<TS> {
  status: 'success'
}

interface Error<TE> extends ApiResponse<TE> {
  status: 'error'
}

interface Http500 {
  status: 500
  code: 500
  content: unknown
}

type Status = 'success' | 'error'

export default handleRequest
