import clsx from 'clsx'
import { HTMLAttributes } from 'react'

import ICONS from './data/icons'

function Icon(props: IconProps) {
  const { className, name, ...rest } = props

  return (
    <div {...rest} className={clsx(className)}>
      {ICONS[name]}
    </div>
  )
}

interface IconProps extends HTMLAttributes<HTMLSpanElement> {
  className?: string
  name: keyof typeof ICONS
}

export default Icon
