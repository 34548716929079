import getCartTotal from '@/helpers/getCartTotal'
import { CartItem } from '@/ts/Cart'

function applyCartLayer(cart: CartItem[]) {
  const product_ids = cart.map(({ id }) => id)
  const total_value = getCartTotal(cart)

  const layer = {
    event: 'content',
    page_type: 'cart',
    product_ids,
    total_value,
  }

  return layer
}

export default applyCartLayer
