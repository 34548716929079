import clsx from 'clsx'
import { AnimatePresence } from 'framer-motion'
import { memo } from 'react'
import { toast as _toast } from 'react-hot-toast'
import { Toast } from 'react-hot-toast/dist/core/types'

import Motion from '@/organisms/Motion'

import Message from './includes/Message'
import StatusIcon from './includes/StatusIcon'

function Body(props: BodyProps) {
  const { message, toast, variant } = props
  const hideToast = () => _toast.dismiss(toast.id)

  return (
    <AnimatePresence exitBeforeEnter>
      {toast.visible && (
        <Motion
          onClick={hideToast}
          animation="fadeScaleDownUp"
          transition={{ duration: 0.2 }}
          className={clsx(
            'flex items-center max-w-xs w-full rounded-lg flex-grow py-5 pl-5 pr-9 space-x-4 break-words cursor-pointer',
            {
              'bg-sbtaquamarine-100 ring-1 ring-sbtaquamarine-200':
                variant === 'success',
              'bg-sbtred-100 ring-1 ring-sbtred-200': variant === 'error',
              'bg-sbtblue-100 ring-1 ring-sbtblue-200': variant === 'info',
            }
          )}
        >
          <StatusIcon variant={variant} />
          <Message value={message} variant={variant} />
        </Motion>
      )}
    </AnimatePresence>
  )
}

export interface BodyProps {
  message: string | JSX.Element
  toast: Toast
  variant: 'success' | 'error' | 'info'
}

export default memo(Body)
