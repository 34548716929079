import reactQueryClient from '@/services/ReactQuery'
import { showModal } from '@/store/slices/modal'
import { User, UserCreatorStatus } from '@/ts/User'

async function handleSocialMediaParam() {
  const user = await reactQueryClient.fetchQuery<User>('user')

  if (!user) {
    showModal('auth', 'providers')
    return
  }

  if (user.creator_status === AwaitingVerification) {
    showModal('auth_creator', 'verification')
  }

  return
}

const { AwaitingVerification } = UserCreatorStatus

export default handleSocialMediaParam
