import { CartItem } from '@/ts/Cart'
import { FansiKindExtended } from '@/ts/Global'
import Product from '@/ts/Product'

import applyCartLayer from './logic/applyCartLayer'
import applyCategoryLayer from './logic/applyCategoryLayer'
import applyCreatorLayer from './logic/applyCreatorLayer'
import applyProductLayer from './logic/applyProductLayer'

// TODO: Include fansi experience before merge to master

class GTM {
  private static push(layer: Record<string, unknown>) {
    if (
      process.env.NODE_ENV === 'production' &&
      process.browser &&
      window?.dataLayer
    ) {
      window.dataLayer.push(layer)
    }
  }

  static pushView(page: string) {
    return GTM.push({ event: 'pageview', page })
  }

  static pushCart(cart: CartItem[]) {
    const layer = applyCartLayer(cart)
    return GTM.push(layer)
  }

  static pushCategory(products: Product[], category: FansiKindExtended) {
    const layer = applyCategoryLayer(products, category)
    return GTM.push(layer)
  }

  static pushCreator(nickname: string, products: Product[]) {
    const layer = applyCreatorLayer(nickname, products)
    return GTM.push(layer)
  }

  static pushProduct = (product: Product) => {
    const layer = applyProductLayer(product)
    return GTM.push(layer)
  }

  static pushOrder(order: Record<string, unknown>) {
    return GTM.push({ event: 'ecommerce', ...order })
  }
}

export default GTM
