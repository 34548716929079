import Cookies from 'js-cookie'

import { setCartProducts } from '@/store/slices/cart'

function handleClearCartCookie() {
  Cookies.remove('should_clear_cart')
  setCartProducts([])
}

export default handleClearCartCookie
