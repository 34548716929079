import { RewriteFrames } from '@sentry/integrations'
import * as Sentry from '@sentry/node'

export const initSentry = () => {
  if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
    const integrations = []
    if (
      process.env.NEXT_IS_SERVER === 'true' &&
      process.env.NEXT_PUBLIC_SENTRY_SERVER_ROOT_DIR
    ) {
      integrations.push(
        new RewriteFrames({
          iteratee: (frame) => {
            frame.filename = (frame.filename as string).replace(
              process.env.NEXT_PUBLIC_SENTRY_SERVER_ROOT_DIR as string,
              'app:///'
            )
            frame.filename = frame.filename.replace('.next', '_next')
            return frame
          },
        })
      )
    }
    Sentry.init({
      enabled: process.env.NODE_ENV === 'production',
      integrations,
      dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
      release: process.env.NEXT_PUBLIC_COMMIT_SHA as string,
      ignoreErrors: ['ResizeObserver loop limit exceeded'],
    })
  }
}
