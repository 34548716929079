export interface User {
  id: number
  email: string
  full_name: string
  birthdate: Date | string
  gender: 'male' | 'female' | 'other' | null
  phone_number: number | string
  creator_status: UserCreatorStatus
  confirmed_at: string
  with_password: boolean
}

export enum UserCreatorStatus {
  Exist = 'creator_exist',
  NotExist = 'creator_does_not_exist',
  AwaitingVerification = 'awaiting_social_medium_activation',
}
