import ProductPrice from '@/ts/interfaces/product/ProductPrice'

class Facebook {
  static track(
    event: TrackEvent,
    data: ProductPrice & {
      content_ids: number[]
    }
  ) {
    if (
      process.env['NEXT_PUBLIC_FACEBOOK_PIXEL_ID'] &&
      process.env.NODE_ENV === 'production' &&
      process.browser
    ) {
      window.fbq('track', event, { ...data, content_type: 'product' })
    } else {
      // eslint-disable-next-line no-console
      console.log(event, data)
    }
  }
}

export interface FacebookPixelContents {
  id: number
}

type TrackEvent = 'ViewContent' | 'AddToCart' | 'Purchase' | 'InitiateCheckout'

export default Facebook
