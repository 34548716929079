import { memo } from 'react'
import { toast as _toast, Toaster } from 'react-hot-toast'
import { ToastOptions } from 'react-hot-toast/dist/core/types'

import Body from './includes/Body'

function Toast() {
  return <Toaster toastOptions={{ duration: 10000 }} />
}

export const toast = {
  success: (message: string | JSX.Element, options?: ToastOptions) =>
    _toast.custom(
      (t) => <Body message={message} toast={t} variant="success" />,
      options
    ),
  error: (message: string | JSX.Element, options?: ToastOptions) =>
    _toast.custom(
      (t) => <Body message={message} toast={t} variant="error" />,
      options
    ),
  info: (message: string | JSX.Element, options?: ToastOptions) =>
    _toast.custom(
      (t) => <Body message={message} toast={t} variant="info" />,
      options
    ),
}

export default memo(Toast)
