enum FansiKind {
  Digital = 'digital',
  FansiClass = 'fansi_class',
  FansiCollection = 'fansi_collection',
  FansiVideo = 'fansi_video',
  FansiExperience = 'fansi_experience',
  FansiProjects = 'fansi_projects',
}

export default FansiKind
