import produce from 'immer'
import create, { GetState, SetState, State, StoreApi } from 'zustand'
import { combine, devtools, persist } from 'zustand/middleware'

function createStore<TPrimary extends State, TSecondary extends State>(
  props: Middleware<TPrimary, TSecondary>
) {
  const { name, state, actions, shouldPersist = false } = props

  const combined = combine(state, immer(actions))
  const persisted = persist(combined, { name: `STORAGE-${name}` })
  const store = shouldPersist ? persisted : combined

  if (process.env.NODE_ENV === 'production') return create(store)

  return create(devtools(store, name))
}

const immer = <T extends State, U extends State>(
  config: StateCreator<T, (fn: (draft: T) => void) => void, U>
): StateCreator<T, SetState<T>, U> => (set, get, api) =>
  config((fn) => set(produce(fn) as (state: T) => T), get, api)

interface Middleware<TPrimary extends State, TSecondary extends State> {
  name: string
  state: TPrimary
  actions: StateCreator<
    TPrimary,
    (fn: (draft: TPrimary) => void) => void,
    TSecondary
  >
  shouldPersist?: boolean
}

type StateCreator<
  T extends State,
  CustomSetState = SetState<T>,
  U extends State = T
> = (set: CustomSetState, get: GetState<T>, api: StoreApi<T>) => U

export default createStore
