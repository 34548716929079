import { Variants } from 'framer-motion'

const animations: Record<MotionAnimation, Variants> = {
  fadeDownUp: {
    initial: { opacity: 0, y: -20 },
    animate: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -20 },
  },
  fadeInOut: {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
  },
  fadeUpOut: {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: 20 },
  },
  fadeRightOut: {
    initial: { opacity: 0, x: -40 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0 },
  },
  fadeScaleDownUp: {
    initial: { opacity: 0, y: -40, scale: 0.9 },
    animate: { opacity: 1, y: 0, scale: 1 },
    exit: { opacity: 0, y: -40, scale: 0.9 },
  },
  scale: {
    initial: { opacity: 0, scale: 0.95 },
    animate: { opacity: 1, scale: 1 },
    exit: { opacity: 0, scale: 0.95 },
  },
  slideUp: {
    initial: { y: 60 },
    animate: { y: 0 },
  },
}

export type MotionAnimation =
  | 'fadeDownUp'
  | 'fadeInOut'
  | 'fadeRightOut'
  | 'fadeUpOut'
  | 'fadeScaleDownUp'
  | 'scale'
  | 'slideUp'

export default animations
