import { CartItem } from '@/ts/Cart'

function removeCollection(cart: CartItem[], product: CartItem) {
  const { id, size_id } = product

  return cart.filter((product) => {
    if (product.type !== 'Product::FansiCollection') return true
    return product.size_id !== size_id || product.id !== id
  })
}

export default removeCollection
