import FansiKind from '@/ts/enums/global/FansiKind'
import ProductType from '@/ts/enums/product/ProductType'

const PRODUCT_AS_FANSI_KIND: Record<ProductType, FansiKind> = {
  'Product::Digital': FansiKind.Digital,
  'Product::FansiClass': FansiKind.FansiClass,
  'Product::FansiCollection': FansiKind.FansiCollection,
  'Product::FansiVideo': FansiKind.FansiVideo,
  'Product::FansiExperience': FansiKind.FansiExperience,
}

export default PRODUCT_AS_FANSI_KIND
