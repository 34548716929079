function excludeBlankKeys<T>(object: Record<string, any>): Record<string, T> {
  Object.entries(object).forEach(([key, val]) => {
    if (val && typeof val === 'object') {
      excludeBlankKeys(val)
    }
    if (val === null || val === '' || val === undefined) {
      delete object[key]
    }
  })
  return object
}

export default excludeBlankKeys
