import { CartItem } from '@/ts/Cart'

function removeClass(cart: CartItem[], product: CartItem) {
  const { type, time_slot_id } = product

  return cart.filter((product) =>
    product.type === type ? product.time_slot_id !== time_slot_id : product
  )
}

export default removeClass
