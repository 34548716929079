import Cookies from 'js-cookie'

import { AuthPerson } from '@/ts/Global'

function revokeSession(authPerson: AuthPerson) {
  Cookies.remove(`${authPerson}_access_token`)
  Cookies.remove(`${authPerson}_refresh_token`)
}

export default revokeSession
