import Router from 'next/router'
import NProgress from 'nprogress'

import useModalStore, { hideModals, ModalName } from '@/store/slices/modal'

function handleRouteChange() {
  Router.events.on('routeChangeStart', () => NProgress.start())
  Router.events.on('routeChangeError', () => NProgress.done())

  Router.events.on('routeChangeComplete', () => {
    const { name } = useModalStore.getState()

    if (!fixedModals.includes(name)) hideModals()

    return NProgress.done()
  })
}

const fixedModals: ModalName[] = ['filter_box']

export default handleRouteChange
