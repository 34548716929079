import uuid from '@/helpers/uuid'
import Facebook from '@/services/Facebook'
import createStore from '@/store/middleware'
import Cart, { CartItem } from '@/ts/Cart'
import Product from '@/ts/Product'

import addProduct from './logic/addProduct'
import removeProduct from './logic/removeProduct'
import updateProduct from './logic/updateProduct'

const useCartStore = createStore({
  name: 'Cart',
  shouldPersist: true,
  state: {
    products: [] as CartItem[],
    stagingProduct: {} as CartItem,
  },

  actions: (set) => ({
    addProductToCart: (product: Product | CartItem) => {
      Facebook.track('AddToCart', {
        ...product.price,
        value: product.price.value / 100,
        content_ids: [product?.id],
      })

      return set((state) => {
        state.stagingProduct = product as CartItem
        state.products = addProduct(state.products, {
          ...product,
          quantity: 1,
          uuid: uuid(),
        } as CartItem)
      })
    },

    removeProductFromCart: (product: Partial<CartItem>) =>
      set((state) => {
        state.products = removeProduct(state.products, product as CartItem)
      }),

    updateProductInCart: (product: Partial<CartItem>) =>
      set((state) => {
        state.products = updateProduct(state.products, product as CartItem)
      }),

    setStagingProduct: (product: Partial<CartItem>) =>
      set((state) => {
        state.stagingProduct = product as CartItem
      }),

    setCartProducts: (products: Partial<CartItem>[] | never[]) =>
      set((state) => {
        state.products = products as CartItem[]
      }),
  }),
})

export const {
  addProductToCart,
  removeProductFromCart,
  updateProductInCart,
  setStagingProduct,
  setCartProducts,
} = useCartStore.getState()

export type CartStore = Cart

export default useCartStore
