import { showModal } from '@/store/slices/modal'
import { CartItem } from '@/ts/Cart'

import addClass from './add/addClass'
import addCollection from './add/addCollection'
import addExperience from './add/addExperience'
import addVideo from './add/addVideo'

function addProduct(cart: CartItem[], product: CartItem) {
  if (product.type === 'Product::FansiClass') {
    return addClass(cart, product)
  }

  if (product.type === 'Product::FansiCollection') {
    return addCollection(cart, product)
  }

  if (product.type === 'Product::FansiVideo') {
    return addVideo(cart, product)
  }

  if (product.type === 'Product::FansiExperience') {
    return addExperience(cart, product)
  }

  const existingProduct = cart.find(({ id }) => id === product.id)

  if (existingProduct) {
    showModal('cart', 'already_in_cart')
    return cart
  }

  showModal('cart', 'added_to_cart')
  return [...cart, product]
}

export default addProduct
