import clsx from 'clsx'
import { motion, MotionProps } from 'framer-motion'
import { HTMLMotionComponents } from 'framer-motion/types/render/dom/types'
import { ReactNode } from 'react'
import { memo } from 'react'

import animations, { MotionAnimation } from './animations'

function Motion(props: CustomMotionProps) {
  const { className, animation = 'fadeInOut', as = 'div', ...rest } = props

  const MotionElement = motion[as]

  return (
    <MotionElement
      className={clsx(className)}
      initial="initial"
      animate="animate"
      exit="exit"
      variants={animations[animation]}
      {...rest}
    >
      {props.children}
    </MotionElement>
  )
}

interface CustomMotionProps extends MotionProps {
  className?: string
  as?: keyof HTMLMotionComponents
  children: ReactNode
  animation?: MotionAnimation
  onClick?(): void
}

export default memo(Motion)
