import { ParsedUrlQuery } from 'querystring'

import { toast } from '@/molecules/Toast'
import { ToastVariant } from '@/ts/Global'

function handleMessageParam(query: ParsedUrlQuery) {
  const message = query.message as string
  const variant = query.alert as ToastVariant

  if (!variants.includes(variant)) return

  return toast[variant](message)
}

const variants = ['success', 'error', 'info']

export default handleMessageParam
