import router from 'next/router'

import { showModal } from '@/store/slices/modal'
import { Param } from '@/ts/Global'

function handleOrderParam(params: Param[]) {
  if (params.includes('error')) {
    showModal('cart', 'payment_declined')
    return
  }

  // showModal('cart', 'order_placed')
  router.push(`/order-placed?basket_id=${router.query.basket_id}`)

  return
}

export default handleOrderParam
