import ENDPOINT from '@/constants/endpoint'

const API_HOST = process.env.NEXT_PUBLIC_API_HOST as string

function getEndpoint(key: keyof typeof ENDPOINT, prefix?: string) {
  const basePath = prefix ?? API_HOST
  return basePath + ENDPOINT[key]
}

export default getEndpoint
