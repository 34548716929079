import Router from 'next/router'

import getEndpoint from '@/helpers/getEndpoint'
import revokeSession from '@/helpers/revokeSession'
import { toast } from '@/molecules/Toast'
import API from '@/services/API'
import reactQueryClient from '@/services/ReactQuery'
import { hideModals } from '@/store/slices/modal'
import { AuthPerson } from '@/ts/Global'

async function signOut(scope: AuthPerson, withToast = true) {
  const endpoint = getEndpoint('SIGN_OUT')
  const body = { client_id, client_secret, scope }

  await API.delete(endpoint, { body })

  reactQueryClient.setQueryData(scope, null)

  revokeSession(scope)

  hideModals()

  if (Router.pathname.includes('customer')) {
    Router.push('/')
  }

  if (withToast) {
    setTimeout(() => toast.success('sign_out'), 300)
  }
}

const client_id = process.env.NEXT_PUBLIC_CLIENT_ID
const client_secret = process.env.NEXT_PUBLIC_CLIENT_SECRET

export default signOut
