import { useRouter } from 'next/router'
import { useEffect } from 'react'

import GTM from '@/services/GTM'

function useGTM() {
  const router = useRouter()

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      router.events.on('routeChangeComplete', GTM.pushView)
      return () => {
        router.events.off('routeChangeComplete', GTM.pushView)
      }
    }
  }, [router.pathname])
}

export default useGTM
