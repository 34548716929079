import clsx from 'clsx'
import useTranslation from 'next-translate/useTranslation'

import { BodyProps } from '../index'

function Message({ value, variant }: MessageProps) {
  const { t } = useTranslation('global')
  const path = `alerts:${variant}.`

  return (
    <p
      className={clsx('text-sm font-medium leading-snug', {
        'text-sbtaquamarine-700': variant === 'success',
        'text-sbtred-600': variant === 'error',
        'text-sbtblue-500': variant === 'info',
      })}
    >
      {typeof value === 'string' ? t(path + value) : value}
    </p>
  )
}

interface MessageProps {
  value: string | JSX.Element
  variant: BodyProps['variant']
}

export default Message
