const ENDPOINT = {
  CHECKOUT: 'marketplace/checkout/',
  CHECKOUT_SIMILAR_PRODUCTS: 'marketplace/checkout/similar_products',
  CHECKOUT_FEATURED_PRODUCTS: 'marketplace/checkout/bestseller_products',
  CONTACT_MESSAGE: 'marketplace/contact_messages',
  CONTACT_WELL_BEING: 'marketplace/contact_messages/wellbeing',
  FAVOURITES: 'marketplace/favorites',
  FAVOURITES_BATCH: 'marketplace/favorites/batch',
  FAVOURITES_REMOVE: 'marketplace/favorites',
  FETCH_DELIVERY_PROVIDERS: 'marketplace/checkout/delivery_providers',
  FETCH_PICKUP_POINTS: 'marketplace/checkout/pickup_points',
  CONFIRMATION_INSTRUCTIONS: 'marketplace/users/send_confirmation_instructions',
  CONFIRM_ACCOUNT: 'marketplace/users/confirm_account',
  DELETE_CREATOR: 'marketplace/profile',
  DELIVERY_ADDRESSES: 'marketplace/profile/delivery_addresses/',
  FANSI_EXPERIENCE: 'marketplace/fansi_experience/',
  GET_USER: 'marketplace/users/me',
  ORDERS: 'marketplace/orders/',
  PASSWORD_CHANGE: 'marketplace/profile/update_password',
  PASSWORD_RESET: 'marketplace/users/reset_password',
  REFRESH_TOKEN: 'auth/refresh_tokens',
  SEND_MESSAGE: 'marketplace/contact_messages',
  SEND_REVIEW: 'marketplace/orders/',
  SIGN_IN_USER: 'auth/sessions/user/',
  SIGN_OUT: 'auth/revoke',
  SIGN_UP_CREATOR: 'marketplace/creators',
  SIGN_UP_USER: 'marketplace/users',
  SURVEY_VOTE: 'marketplace/surveys/:id/vote',
  UPDATE_DELIVERY_ADDRESS: 'marketplace/profile/delivery_addresses/',
  UPDATE_PROFILE: 'marketplace/profile',
  VERIFY_EMAIL: 'marketplace/users/check_email_availability',
  VERIFY_CREATOR_ENTRIES:
    'marketplace/creators/check_nickname_and_phone_availability',
  VERIFY_PROMO_CODE: 'marketplace/checkout/check_promo_code/',
  VERIFY_SOCIAL_MEDIA: 'marketplace/creators/verify_social_medium',
}

export default ENDPOINT
