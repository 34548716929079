import { showModal } from '@/store/slices/modal'
import { CartItem } from '@/ts/Cart'

function addClass(cart: CartItem[], product: CartItem) {
  const classes = cart.filter(({ type }) => type === 'Product::FansiClass')
  if (classes.length > 0) {
    const slotInCart = classes.find(
      ({ time_slot_id }) => time_slot_id === product.time_slot_id
    )

    if (slotInCart) {
      showModal('cart', 'already_in_cart')
      return cart
    }
  }

  showModal('cart', 'added_to_cart')

  return [...cart, product]
}

export default addClass
