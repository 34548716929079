import { hideModals, showModal } from '@/store/slices/modal'
import { CartItem } from '@/ts/Cart'

function addVideo(cart: CartItem[], product: CartItem) {
  hideModals()
  setTimeout(() => showModal('cart', 'added_to_cart'), 200)

  return [...cart, product]
}

export default addVideo
