import JSCookies from 'js-cookie'
import { useEffect } from 'react'

import handleClearCartCookie from '@/helpers/cookies/handleClearCartCookie'

function useCookies() {
  const cookies = Object.keys(JSCookies.get() || {}) as Cookies[]

  const run = async () => {
    if (cookies.includes('should_clear_cart')) {
      handleClearCartCookie()
    }
  }

  useEffect(() => {
    if (cookies.length > 0) run()
  }, [])
}

export const supportedCookies = ['should_clear_cart'] as const

type Cookies = typeof supportedCookies[number]

export default useCookies
