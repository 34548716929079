import Product from '@/ts/Product'

function applyCreatorLayer(nickname: string, products: Product[]) {
  const product_ids = products.map(({ id }) => id)

  const layer = {
    event: 'content',
    page_type: 'creator',
    creator: nickname,
    product_ids,
  }

  return layer
}

export default applyCreatorLayer
