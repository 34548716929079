
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import "@/css/application.css"
import "@/css/global.css"
import "intl-pluralrules"

import Cookies from "js-cookie"
import { AppLayoutProps } from "next/app"
import dynamic from "next/dynamic"
import { useRouter } from "next/router"
import { useEffect } from "react"
import { QueryClientProvider } from "react-query"

import handleRouteChange from "@/helpers/handleRouteChange"
import { initSentry } from "@/helpers/initSentry"
import useCookies from "@/hooks/useCookies"
import useGTM from "@/hooks/useGTM"
import useQueryParams from "@/hooks/useQueryParams"
import reactQueryClient from "@/services/ReactQuery"
import Modals from "@/templates/Modals"
import Noop from "@/templates/Noop/Noop"

const CookiesBar = dynamic(() => import("@/organisms/CookiesBar"), {
  ssr: false,
})
const Toast = dynamic(() => import("@/molecules/Toast"), { ssr: false })

handleRouteChange()

function App({ Component, pageProps }: any & AppLayoutProps) {
  const { locale } = useRouter()

  const hasAcceptedCookies = Cookies.get("cookies_consent")
  const Layout = Component.Layout || Noop

  initSentry()

  useGTM()

  useGTM()
  useCookies()
  useQueryParams()

  useEffect(() => {
    Cookies.set("NEXT_LOCALE", locale as string)
  }, [])

  return (
    <QueryClientProvider client={reactQueryClient}>
      {!hasAcceptedCookies && <CookiesBar />}
      <Toast />
      <Modals />
      <Layout>
        <Component {...pageProps} />
      </Layout>
    </QueryClientProvider>
  )
}

const __Page_Next_Translate__ = App


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: true,
// @ts-ignore
      
// @ts-ignore
    });
// @ts-ignore
  