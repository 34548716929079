import handleRequest from './logic/handleRequest'

class API {
  private static request = async <TS, TE>(
    endpoint: string,
    method: ApiMethod,
    options?: RequestOptions
  ) => await handleRequest<TS, TE>({ endpoint, method, options })

  static get = async <TS, TE = unknown>(
    endpoint: string,
    options?: RequestOptions
  ) => await API.request<TS, TE>(endpoint, 'GET', options)

  static post = async <TS, TE = unknown>(
    endpoint: string,
    options: RequestOptions
  ) => await API.request<TS, TE>(endpoint, 'POST', options)

  static put = async <TS, TE = unknown>(
    endpoint: string,
    options: RequestOptions
  ) => await API.request<TS, TE>(endpoint, 'PUT', options)

  static delete = async <TS, TE = unknown>(
    endpoint: string,
    options?: RequestOptions
  ) => await API.request<TS, TE>(endpoint, 'DELETE', options)
}

export interface RequestOptions {
  body?: Record<string, unknown>
  formData?: Record<string, unknown>
  cookies?: string
}

export type ApiMethod = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH'

export default API
