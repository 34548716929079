import { CartItem } from '@/ts/Cart'

import removeClass from './remove/removeClass'
import removeCollection from './remove/removeCollection'

function removeProduct(cartItems: CartItem[], product: CartItem) {
  if (product.type === 'Product::FansiCollection') {
    return removeCollection(cartItems, product)
  }

  if (product.type === 'Product::FansiClass') {
    return removeClass(cartItems, product)
  }

  return cartItems.filter(({ uuid }) => uuid !== product.uuid)
}

export default removeProduct
