import Product, { ProductPrice } from '@/ts/Product'

export default function getProductPrice(
  product: Partial<Product>
): ProductPrice {
  return (
    product?.price ?? {
      value: 0,
      price_before_discount: 0,
      currency: 'PLN',
    }
  )
}
