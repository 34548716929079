import Router from 'next/router'

import confirmAccount from '@/api/requests/auth/confirmAccount'
import { toast } from '@/molecules/Toast'
import { showModal } from '@/store/slices/modal'

async function handleAccountConfirmationParam() {
  const token = Router.query.confirmation_token as string
  const response = await confirmAccount(token)

  if (response.status === 'success') {
    showModal('auth', 'account_activation')
    return
  }

  if (response.status === 'error') {
    toast.error('account.activation_token')
  }

  return
}

export default handleAccountConfirmationParam
