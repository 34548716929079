import { useRouter } from 'next/router'
import { useEffect } from 'react'

import signOut from '@/api/requests/auth/signOut'
import handleAccountConfirmationParam from '@/helpers/query-params/handleAccountConfirmationParam'
import handleAuthParam from '@/helpers/query-params/handleAuthParam'
import handleMessageParam from '@/helpers/query-params/handleMessageParam'
import handleOrderParam from '@/helpers/query-params/handleOrderParam'
import handleSocialMediaParam from '@/helpers/query-params/handleSocialMediaParam'
import { showModal } from '@/store/slices/modal'
import { Param } from '@/ts/Global'

function useQueryParams() {
  const router = useRouter()
  const params = Object.keys(router.query) as Param[]
  const shouldRun = params.some((param) => supportedParams.includes(param))

  const run = async () => {
    if (params.includes('auth')) {
      return await handleAuthParam()
    }
    if (params.includes('confirmation_token')) {
      return await handleAccountConfirmationParam()
    }
    if (params.includes('message')) {
      return handleMessageParam(router.query)
    }
    if (params.includes('basket_id')) {
      return handleOrderParam(params)
    }
    if (params.includes('reset_password_token')) {
      return showModal('auth', 'password_reset')
    }
    if (params.includes('revoke')) {
      return await signOut('user')
    }
    if (params.includes('social_media_verification')) {
      return handleSocialMediaParam()
    }
  }

  useEffect(() => {
    if (router.isReady && shouldRun) {
      const runner = setTimeout(() => run(), 500)
      return () => clearTimeout(runner)
    }
  }, [router.route])
}

export const supportedParams = [
  'auth',
  'basket_id',
  'reset_password_token',
  'confirmation_token',
  'social_media_verification',
  'revoke',
  'message',
  'error',
] as const

export default useQueryParams
