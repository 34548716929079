import { FansiKindExtended } from '@/ts/Global'
import Product from '@/ts/Product'

function applyCategoryLayer(products: Product[], category: FansiKindExtended) {
  const product_ids = products.map(({ id }) => id)
  const total_value = products
    .map(({ entries, price, type }) =>
      type === 'Product::FansiExperience'
        ? entries[0]?.price?.value
        : price?.value
    )
    .reduce((a, b) => a + b, 0)

  const layer = {
    event: 'content',
    page_type: 'category',
    category,
    product_ids,
    total_value,
  }

  return layer
}

export default applyCategoryLayer
