import clsx from 'clsx'

import Icon from '@/atoms/Icon'
import { IconName } from '@/ts/Global'

import { BodyProps } from '..'

function StatusIcon({ variant }: StatusIconProps) {
  return (
    <div
      className={clsx(
        'w-6 h-6 rounded-full flex-shrink-0 flex items-center justify-center text-white',
        {
          'bg-sbtaquamarine-500 ring-sbtaquamarine-300 ring-4':
            variant === 'success',
          'ring-sbtred-200 ring-4 bg-sbtred-500': variant === 'error',
          'bg-sbtblue-500 ring-4 ring-sbtblue-200': variant === 'info',
        }
      )}
    >
      <Icon className="w-2.5 2sm:w-3" name={names[variant]} />
    </div>
  )
}

interface StatusIconProps {
  variant: BodyProps['variant']
}

const names: Record<StatusIconProps['variant'], IconName> = {
  success: 'check_mark',
  error: 'warning',
  info: 'info',
}

export default StatusIcon
