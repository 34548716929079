import dynamic from 'next/dynamic'
import { memo } from 'react'

import useModalStore from '@/store/slices/modal'

const AuthModal = dynamic(() => import('@/modals/Auth'), {
  ssr: false,
})

const AuthCreatorModal = dynamic(() => import('@/modals/AuthCreator'), {
  ssr: false,
})

const CartModal = dynamic(() => import('@/modals/Cart'), { ssr: false })

const CustomerModal = dynamic(() => import('@/modals/Customer'), {
  ssr: false,
})

const MobileMenu = dynamic(() => import('@/organisms/MobileMenu'), {
  ssr: false,
})

const WellBeingMobileMenu = dynamic(
  () => import('@/organisms/WellBeingMobileMenu'),
  {
    ssr: false,
  }
)

const DeliveryAddressModal = dynamic(() => import('@/modals/DeliveryAddress'), {
  ssr: false,
})

const SurveyModal = dynamic(() => import('@/modals/Survey'), {
  ssr: false,
})

const RemoveCustomer = dynamic(() => import('@/modals/RemoveCustomer'), {
  ssr: false,
})

function Modals() {
  const { name } = useModalStore((state) => state)

  if (name === 'auth') {
    return <AuthModal />
  }

  if (name === 'auth_creator') {
    return <AuthCreatorModal />
  }

  if (name === 'cart') {
    return <CartModal />
  }

  if (name === 'customer') {
    return <CustomerModal />
  }

  if (name === 'delivery_address') {
    return <DeliveryAddressModal />
  }

  if (name === 'mobile_menu') {
    return <MobileMenu />
  }

  if (name === 'well_being_mobile_menu') {
    return <WellBeingMobileMenu />
  }

  if (name === 'remove_account') {
    return <RemoveCustomer />
  }

  if (name === 'survey') {
    return <SurveyModal />
  }

  return null
}

export default memo(Modals)
