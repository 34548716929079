module.exports = {
  locales: ['pl'],
  defaultLocale: 'pl',
  loadLocaleFrom: (lang, namespace) =>
    import(`src/tools/locales/${lang}/${namespace}.json`).then(
      (m) => m.default
    ),
  pages: {
    '*': ['alerts', 'global', 'forms', 'auth_user', 'products'],
    '/': ['footer', 'auth_creator', 'home', 'products'],
    '/about': ['auth_user', 'footer', 'auth_creator', 'about'],
    '/for-creators': ['auth_user', 'footer', 'auth_creator', 'forCreators'],
    '/for-brands': ['auth_user', 'footer', 'auth_creator', 'forBrands'],
    '/contact': ['auth_user', 'footer', 'auth_creator', 'contact'],
    '/cart': ['products', 'auth_user', 'auth_creator', 'footer'],
    '/checkout': ['products', 'auth_user', 'footer', 'auth_creator'],
    '/well-being': ['auth_user', 'footer', 'auth_creator', 'wellBeing'],
    'rgx:/customer': [
      'auth_user',
      'footer',
      'auth_creator',
      'customer',
      'status',
      'products',
      'orders',
    ],
    'rgx:/creators': [
      'auth_user',
      'footer',
      'auth_creator',
      'products',
      'creators',
      'surveys',
    ],
    'rgx:/groups': ['auth_user', 'footer', 'auth_creator', 'products'],
    'rgx:/products': ['auth_user', 'footer', 'auth_creator', 'products'],
    '/search': ['auth_user', 'footer', 'auth_creator', 'products'],
    'rgx:/help': ['auth_user', 'footer', 'auth_creator', 'help'],
    '/help': ['helpHowItWorks'],
    '/help/vouchers': ['help_vouchers'],
    '/help/discounts': ['help_discounts'],
    '/help/become-a-creator': ['helpBecomeCreator'],
    '/help/digital-products': ['helpDigitalProducts'],
    '/help/fansi-class': ['helpFansiClass'],
    '/help/fansi-collections': ['helpFansiCollections'],
    '/help/fansi-video': ['helpFansiVideo'],
    '/help/my-account': ['helpMyAccount'],
    '/help/payments': ['helpPayments'],
    '/help/returns-and-complaints': ['helpReturnsAndComplaints'],
    '/help/shopping-in-fansi': ['helpShoppingOnFansi'],
    '/help/signin-and-signup': ['helpSignInAndSignUp'],
    '/order-placed': ['products', 'customer', 'status', 'global'],
    '/surveys/[id]': ['surveys'],
  },
}
