import { CartItem } from '@/ts/Cart'

import getProductPrice from './getProductPrice'

function getCartTotal(items: CartItem[]) {
  return items
    .map((item) => item.quantity * getProductPrice(item).value)
    .reduce((a, b) => a + b, 0)
}

export default getCartTotal
