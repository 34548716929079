import getEndpoint from '@/helpers/getEndpoint'
import API from '@/services/API'

async function confirmAccount(confirmation_token: string) {
  const endpoint = getEndpoint('CONFIRM_ACCOUNT')
  const body = { confirmation_token }

  const response = await API.post(endpoint, { body })

  return response
}

export default confirmAccount
