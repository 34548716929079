import { toast } from '@/molecules/Toast'
import { showModal } from '@/store/slices/modal'
import { CartItem } from '@/ts/Cart'

function addCollection(cart: CartItem[], product: CartItem) {
  const { type, size_id, id } = product

  let newCart: CartItem[]

  const alreadyInCart = cart.some((item) => {
    if (item.type === type) {
      return item.id === id && item.size_id === size_id
    }
  })

  const productInTheRightSizeQuantity =
    product.sizes?.find((el) => el.id === size_id)?.quantity || 0

  const cartItemQuantity =
    cart.find((el) => el.id === id && el.size_id === size_id)?.quantity || 0

  const noItemsLeft = productInTheRightSizeQuantity <= cartItemQuantity

  if (alreadyInCart && noItemsLeft) {
    toast.error('no_items_left')
    return (newCart = [...cart])
  }

  if (alreadyInCart) {
    newCart = cart.map((item) => {
      if (item.type === type) {
        if (item.id === id && item.size_id === size_id) {
          return { ...item, quantity: item.quantity + 1 }
        }
      }
      return item
    })
  } else {
    newCart = [...cart, product]
  }

  showModal('cart', 'added_to_cart')

  return newCart
}

export default addCollection
