import createStore from '../../middleware'

const useModalStore = createStore({
  name: 'Modal',
  state: {
    name: null as ModalName,
    content: null as ModalContent,
    data: {} as Record<string, any>,
  },
  actions: (set) => ({
    showModal: <T extends ModalName>(
      name: T,
      content?: ModalContent<T>,
      data?: ModalData<T>
    ) =>
      set((state) => {
        state.name = name
        state.content = content || null
        if (data) state.data = data
      }),

    hideModals: () => set(() => ({ name: null, content: null, data: {} })),
  }),
})

export const { showModal, hideModals } = useModalStore.getState()

export type ModalName =
  | 'filter_box'
  | 'auth'
  | 'mobile_menu'
  | 'delivery_address'
  | 'auth_creator'
  | 'cart'
  | 'removal'
  | 'remove_account'
  | 'customer'
  | 'survey'
  | 'well_being_mobile_menu'
  | null

export type ModalContent<T = ModalName> = T extends 'auth'
  ? AuthContent
  : T extends 'auth_creator'
  ? AuthCreatorContent
  : T extends 'delivery_address'
  ? DeliveryAddressContent
  : T extends 'cart'
  ? CartContent
  : T extends 'customer'
  ? CustomerContent
  : T extends 'survey'
  ? SurveyContent
  : null

export type ModalData<T = ModalName> = T extends 'auth'
  ? { withCheckoutRedirect: boolean }
  : Record<string, any>

export type AuthContent =
  | 'providers'
  | 'sign_in'
  | 'sign_up'
  | 'sign_up_confirmation'
  | 'password_recovery'
  | 'password_recovery_confirmation'
  | 'password_reset'
  | 'awaiting_email_confirmation'
  | 'account_activation'

export type AuthCreatorContent =
  | 'profile'
  | 'social_media'
  | 'confirmation'
  | 'verification'
  | 'awaiting_verification'

export type DeliveryAddressContent = 'edit' | 'new' | 'removal'

export type CustomerContent = 'complaint' | 'review'

export type SurveyContent = 'vote' | 'redirect'

export type CartContent =
  | 'added_to_cart'
  | 'all_time_slots'
  | 'already_in_cart'
  | 'video'
  | 'unavailable_products'
  | 'payment_declined'
  | 'join_fansi'

export default useModalStore
